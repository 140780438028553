@import url(https://fonts.googleapis.com/css?family=Merriweather:100,200,300,300i,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "Open Sans", serif;
}

.wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#e67e22), to(#e74c3c));
  background: linear-gradient(to right, #e67e22, #e74c3c);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  height: 90vh;
  background: #fff;
  box-shadow: 0px 13px 40px -13px rgba(0, 0, 0, 0.75);
  width: 80%;
  margin: 0 auto;
}

button {
  border: 0;
  padding: 8px 20px;
  margin: 0 2px;
  border-radius: 2px;
  font-weight: lighter;
  letter-spacing: 1px;
  font-size: 15px;
  cursor: pointer;
  background-color: #f16051;
  color: #fff;
  font-weight: 100;
}

button:active {
  outline: none;
}

